async function get(){
    let response = await fetch('https://api.etherscan.io/api?module=gastracker&action=gasoracle');
    let data = await response.json();
    let SafeGasPrice = data.result.SafeGasPrice;
    document.getElementById("gas-meter").innerHTML = SafeGasPrice;
}

window.onload = get;

setInterval( function() {
    get()
}, 30000);